import React, { useEffect, useState } from "react";
import { Row, Col, Button, Stack } from "react-bootstrap";
import DivideModal from "../components/bill/DivideModal";
import PaymentModal from "../components/bill/PaymentModal";
import OrderPlacedModal from "../components/bill/OrderPlacedModal";
import ModalInterface from "../common/ModalInterface";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OrderBill } from "../components/bill/OrderBill";
import PaypalModal from "../components/bill/PaypalModal";
import CreditModal from "../components/bill/CreditModal";
import CreditCardModal from "../components/bill/CreditCardModal";
import Slider from "react-slick";
import OrderConformationModal from "../components/modals/OrderConformationModal";
import {
  getCourses,
  loadOrdersBySession,
  SetLoading,
  SetOrderPayment,
  setPaymentStatus,
  payWaiter
} from "../store/actions/orderAction";
import { socket } from "../services/socket.services";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Bill = () => {
  const placedOrder = useSelector((state) => state.order.ordersPlaced);
  const [selectedItems, setSelectedItems] = useState([])
  const [showPay, setShowPay] = useState(false);
  const [showDivide, setShowDivide] = useState(false);
  const [showFinal, setShowFinal] = useState(false);
  // const [handleClose, setHandleClose] = useState(false);
  const handleClose = false;

  const [showPayPal, setShowPaypal] = useState(false);
  const [finalBill, setFinalBill] = useState(0);
  const [showCredit, setShowCredit] = useState(false);
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [countToBill, setCountToBill] = useState(0);
  const [divisionCount, setDivisionCount] = useState(0); //for splitting bill
  const [billAmount, setBillAmount] = useState(0); //for split bill and complete bill
  const [modalProceed, setmodalProceed] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);
  const [divisionAmount, setDivisionAmount] = useState(0);
  const [paymentType, setPaymentType] = useState(null);
  const [waiterMsg, setShowWaiterMsg] = useState(false);
  const [finalDivideCount, setFinalDivideCount] = useState(0); //TODO REFACTOR DIVIDE_COUNT LATER
  const [selectedBillAmount, setSelectedBillAmount] = useState(0);
  const [selectedBill, setSelectedBill] = useState(false);
  const [divideBill, setDivideBill] = useState(false);
  const [payWaiterSelected, setPayWaiterSelected] = useState(false);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const [disableButton, setdisableButton] = useState({
    pay_all: false,
    pay_divide: false,
    pay_selected: false
  });

  // const [sEntries, setEntries] = useState(null);
  // const [payOption, setPayOption] = useState("");

  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.bill);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order);
  const busInfo = useSelector((state) => state.table.orderInfo);
  const sessionId = useSelector((state) => state.table.sessionId);
  const loading = useSelector((state) => state.order.loading);
  const orderInfo = useSelector((state) => state.table.orderInfo);
  const tableName = useSelector((state) => state?.table?.table);
  const tableInfo = useSelector((state) => state?.table?.orderInfo);

  let navigate = useNavigate();

  let ListCart = [];
  let TotalCart = 0.0;

  const payload = {
    businessLocationId: busInfo?.businessLocationId,
    sessionId: sessionId
  };

  let se = orders?.ordersPlaced?.flat()?.map((btx) => btx?.salesEntries);
  se = se?.flat();
  let checkPayment = orders?.ordersPlaced?.flat();
  const checkPaymentStatus = () => {
    setShowWaiterMsg(false);
    const updateButtonStatus = (status) => {
      setdisableButton({
        ...disableButton,
        pay_all: status?.pay_all,
        pay_divide: status?.pay_divide,
        pay_selected: status?.pay_selected
      });
    };

    if (checkPayment?.length >= 1) {
      if (Object.prototype.hasOwnProperty.call(checkPayment[0], "payment_type")) {
        if (checkPayment[0]?.payment_type === "pay_selected") {
          updateButtonStatus({
            pay_all: true,
            pay_divide: true,
            pay_selected: false
          });
          setPaymentType("pay_selected");
        } else if (checkPayment[0]?.payment_type === "pay_selected_waiter") {
          updateButtonStatus({
            pay_all: true,
            pay_divide: true,
            pay_selected: false
          });
          setPaymentType("pay_selected_waiter");
        } else if (checkPayment[0]?.payment_type === "pay_divide") {
          updateButtonStatus({
            pay_all: true,
            pay_divide: false,
            pay_selected: true
          });
          // setPayWaiterSelected(true);
          setPaymentType("pay_divide");
        } else if (go[0]?.includes(true) && checkPayment[0]?.payment_type === "pay_waiter") {
          setShowWaiterMsg(true);
          updateButtonStatus({
            pay_all: true,
            pay_divide: true,
            pay_selected: true
          });
          setPaymentType("pay_waiter");
        } else if (
          go[0]?.includes(true) &&
          checkPayment[0]?.payment_type === "pay_waiter_selected"
        ) {
          setShowWaiterMsg(true);
          updateButtonStatus({
            pay_all: true,
            pay_divide: true,
            pay_selected: false
          });
          setPaymentType("pay_waiter_selected");
          setPayWaiterSelected(true);
        } else if (checkPayment[0]?.payment_type === "pay_waiter") {
          setShowWaiterMsg(true);
          updateButtonStatus({
            pay_all: true,
            pay_divide: true,
            pay_selected: true
          });
          setPaymentType("pay_waiter");
        } else {
          updateButtonStatus({
            pay_all: false,
            pay_divide: false,
            pay_selected: false
          });
          setPaymentType("pay_all");
        }
      } else {
        updateButtonStatus({
          pay_all: false,
          pay_divide: false,
          pay_selected: false
        });
        setPaymentType("pay_all");
      }
    } else {
      updateButtonStatus({
        pay_all: false,
        pay_divide: false,
        pay_selected: false
      });
    }
  };

  const checkDivisionStatus = () => {
    if (checkPayment?.length >= 1) {
      if (Object.prototype.hasOwnProperty.call(checkPayment[0], "division_amount")) {
        checkPayment[0]?.division_amount > 0 && setDivisionAmount(checkPayment[0]?.division_amount);
      }
    }
  };
  useEffect(() => {
    if (orders?.courses?.length <= 0) {
      dispatch(getCourses());
    }
    dispatch(SetOrderPayment([]));
    dispatch(setPaymentStatus(null));
    calculateAndMapBill();
    sessionStorage.setItem('paymentStarted', false)
  }, []);

  const calculateAndMapBill = () => {
    if (orders?.ordersPlaced?.length >= 1) {
      Object.keys(se)?.forEach(function (item) {
        let itemPrice = se[item]?.quantity * se[item]?.itemPrice;
        let ingPrice = 0;
        if (se[item]?.subLineItems == null) {
          ingPrice = 0;
        } else {
          ingPrice =
            se[item]?.subLineItems[item]?.quantity * se[item]?.subLineItems[item]?.itemPrice;
        }

        if (typeof ingPrice !== "number" || !isNaN(ingPrice)) {
          TotalCart += itemPrice + ingPrice;
        } else {
          TotalCart += itemPrice;
        }
        ListCart?.push(se[item]);
      });
    }
  };

  // const [images, setImages] = useState(["mod7", "mod8", "mod9", "mod10", "mod3", "mod2", "sides"]);
  const images = ["mod7", "mod8", "mod9", "mod10", "mod3", "mod2", "sides"];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          arrows: false
        }
      }
    ]
  };

  useEffect(() => {
    setShowWaiterMsg(false);
    dispatch(SetLoading(true));
    setBillAmount(TotalCart);
    dispatch(loadOrdersBySession(payload));
    checkPaymentStatus();
    checkDivisionStatus();
  }, []);
  useEffect(() => {
    checkPaymentStatus();
  }, [checkPayment[0]?.payment_type]);
  function TotalPrice(price, tonggia) {
    let total = Number(price * tonggia).toLocaleString("en-US");

    return total;
  }

  const calculateBill = (option) => {
    //complete bill
    if (option === 1) {
      setBillAmount(finalBill);
    }
    //split bill
    else if (divisionCount > 1) {
      setBillAmount(finalBill / divisionCount);
      setDivisionCount(1);
    }
  };
  useEffect(() => {
    socket.on("order_received", (data) => {
      if (sessionId === data.sessionId) {
        dispatch(loadOrdersBySession(payload));
      }
    });
  }, [socket]);
  let go = placedOrder?.map((item) => item?.salesEntries?.map((item) => item.disabled));

  const notifyKitchen = () => {
    let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${tableName} | Order Total: €${finalBill.toFixed(
      2
    )}`; //Tip Amount: €${(total - subAmount).toFixed(2)
    const msg = {
      problemID: uuidv4(),
      tableNo: tableInfo?.tableNumber,
      sessionId: sessionId,
      message: msgDetails.trim(),
      payWaiter: true
    };
    socket.emit("service_notification", msg);
    msgDetails = msgDetails + `| Table No: ${tableInfo?.tableNumber}`;
    return msgDetails;
  };

  const orderID = useSelector((state) => state?.order?.ordersPlaced[0]?._id);
  const addPaymentInfo = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BackendURL + "/payment-info/addOrder", {
        orderId: orderID,
        status: "Created",
        tableNumber: tableInfo?.tableNumber,
        tableName: tableName,
        sessionId:sessionId
      });

      console.log("Creating new payment infos", response.data);
    } catch (error) {
      console.error("Error adding payment info:", error.message);
    }
  };


  const getPaymentInfo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BackendURL + `/payment-info/${orderID}`, {
      })
      if(response.data === "Paid" || response.data === "Canceled")
      return true;
    } catch (error) {
      console.error("Error fetching payment info:", error.message);
    }
  };
  const getCanceledPaymentInfo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BackendURL + `/payment-info/${orderID}`, {
      })
      if(  response.data === "Canceled")
        return true;
    } catch (error) {
      console.error("Error fetching payment info:", error.message);
    }
  };
  const [isPaymentFinished, setIsPaymentFinished] = useState(false);
  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentInfo = await getPaymentInfo();
        const canceledPaymentInfo = await getCanceledPaymentInfo();
        setIsPaymentFinished(paymentInfo);
        setIsPaymentCanceled(canceledPaymentInfo)
      } catch (error) {
        console.error("Error fetching payment info:", error.message);
      }
    };

    fetchData();
  }, [orderID])
  const handleWaiterPayment = () => {
    const waiterPayload = {
      businessLocationId: orderInfo?.businessLocationId,
      thirdPartyReference: uuidv4(),
      orderType: "served",
      sessionID: sessionId,
      tableNumber: orderInfo?.tableNumber,
      orderNote: notifyKitchen(),
      totalAmount: finalBill
    };
    dispatch(payWaiter(waiterPayload)).then(() => {
      setTimeout(() => {
        navigate("/paymentsuccess", { state: { payment_type: "pay_waiter" } });
      }, 5000);
    });
  };
  return (
    <>
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
        className=" mt-5 pt-4">
        <div className="logo" style={{ backgroundColor: " rgb(237, 237, 237);" }}>
          <div className="mainLogo">
            <img
              src={"/img/vapiano_logo.png"}
              alt="main_logo"
              width="85%"
              style={{ maxWidth: "280px", verticalAlign: "middle" }}
            />
          </div>
        </div>
        <h5 className={` ${waiterMsg && !loading ? "text-center w-100" : "d-none"}`}>
          Your order will be marked as soon as waiter marks ur order
        </h5>
        <Stack
          className="justify-content-between align-items-center d-flex mb-5 mt-1"
          direction="horizontal">
          <div style={{ color: "#33ab53" }} className="hdngflex">
            <h1
              className="billHdngflex"
              style={{ color: "#33ab53", fontSize: "30px", fontWeight: "600" }}>
              DEINE
            </h1>
            <h3 className="billHandwritten">
              <i>Bestellung</i>
            </h3>
          </div>
          <div
            style={{ fontSize: "35px", width: "80px", verticalAlign: "middle" }}
            className="me-2">
            <img
              src={page_theme?.right_icon_url}
              className="img-responsive"
              width={85}
              height={85}
              alt={"bill_image"}
            />
          </div>
        </Stack>

        {/* <hr className=" p-1" style={{ backgroundColor: "#FF0000" }} /> */}

        {orders?.ordersPlaced?.length === 0 && !loading ? (
          <>
            <h2
              style={{
                // fontFamily: "'Barlow Condensed', sans-serif",
                fontWeight: 600,
                color: page_theme?.primary_empty_message_text_color
              }}
              className={`p-3 text-center font-${page_theme?.font_family_primary_empty_message} fs-${page_theme?.font_size_empty_message_title} `}>
              PLACE AN ORDER TO CONTINUE
              <hr
                className=""
                style={{
                  backgroundColor: "#FF0000",
                  opacity: 1,
                  height: "2px"
                }}
              />
              <Stack direction="horizontal" className="">
                <Row>
                  <Col>
                    <h1>RECHNUNGSSUMME</h1>
                  </Col>
                  <Col>
                    <h1 className="text-dark" style={{ fontSize: "30px" }}>
                      €
                    </h1>
                  </Col>
                </Row>
              </Stack>
            </h2>

            <div className="placebtn">
              <button
                style={{
                  backgroundColor: page_theme?.primary_pay_all_btn_bgcolor,
                  color: page_theme?.primary_pay_all_btn_text_color
                }}
                type="button"
                className={`billButton btncheck btnblue forPayAll btn font-${page_theme?.font_family_primary_pay_all_btn} fs-${page_theme?.font_size_pay_all_btn}`}>
                ALLES BEZAHLEN
              </button>

              {/* <button
                style={{
                  backgroundColor: page_theme?.primary_pay_divide_btn_bgcolor,
                  color: page_theme?.primary_pay_divide_btn_text_color
                }}
                type="button"
                className={`billButton btncheck btnblue font-${page_theme?.font_family_primary_pay_divide} fs-${page_theme?.font_size_pay_divide_btn}`}>
                RECHNUNG AUFTEILEN
              </button> */}
              {/* <button
                style={{
                  backgroundColor: page_theme?.primary_payselected_btn_bgcolor,
                  color: page_theme?.primary_pay_selected_btn_text_color
                }}
                type="button"
                className={`billButton btncheck btnblue font-${page_theme?.font_family_primary_pay_selected} fs-${page_theme?.font_size_pay_selected_btn} `}>
                AUSGEWÄHLT BEZAHLEN
              </button> */}
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <div className="text-center">
                <span
                  className="spinner-border spinner-border-lg text-danger"
                  role="status"
                  aria-hidden="true"></span>
              </div>
            ) : (
              <>
                <div className="ps-4 pe-4 mx-0">
                  <OrderBill
                    disableSelection={
                      divisionAmount > 0.0 && paymentType === "pay_divide" ? true : false
                    }
                    // setEntries={setEntries}
                    setFinalBill={setFinalBill}
                    countToBill={countToBill}
                    setCountToBill={setCountToBill}
                    orders={orders}
                    TotalPrice={TotalPrice}
                    TotalCart={TotalCart}
                    ListCart={ListCart}
                    setSelectedBill={setSelectedBillAmount}
                    paymentType={paymentType}
                    divisionAmount={divisionAmount > 0 ? divisionAmount : 0}

                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                  />
                </div>
                <div className=" billbtntextmain">
                  {
                    //here add online-pay check
                    paymentType === "online_payment" ||
                      orders?.ordersPlaced[0]?.payment_status !== "pending" ? (
                      <div className="billALLButtonGroup">
                        <Button
                          // size="lg"
                          disabled={disableButton.pay_all || isPaymentFinished}
                          className={`billButton btn font-${page_theme?.font_family_primary_pay_all_btn} font-${page_theme?.font_family_primary_pay_all_btn} fs-${page_theme?.font_size_pay_all_btn}`}
                          style={{
                            backgroundColor: page_theme?.primary_pay_all_btn_bgcolor,
                            color: page_theme?.primary_pay_all_btn_text_color
                          }}
                          onClick={() => {
                            // setPayOption("payall");
                            // setShowConfirmOrder(true);
                            setShowPay(true);
                            calculateBill(1);
                            addPaymentInfo();
                          }}>
                          ALLES BEZAHLEN
                        </Button>
                      </div>
                    ) : (
                      <div className="billButtonGroup pb-5 px-5">
                        <div
                          className="btnPayBill"
                          onClick={() => {
                            setShowConfirmOrder(true);
                            setPaymentType("pay_waiter");
                          }}>
                          <div role="button" className="paymentBillButton2">
                            <img
                              src="img/waiter_pay.png"
                              alt="payment_to_waiter"
                              className="img-responsive rounded shadow-lg"
                              style={{
                                height: "40px",
                                backgroundColor: "#15a139",
                                backgroundBlendMode: "multiply"
                              }}
                            />
                          </div>
                          <p className="mt-1 text-center">BEIM KELLNER BEZAHLEN</p>
                        </div>
                        <div className="btnPayBill">
                          <div
                            className="borders-0 paymentWaiterButton2"
                            role="button"
                            // variant="success"
                            onClick={() => {
                              setShowConfirmOrder(true);
                              setPaymentType(null);
                            }}>
                            <img
                              src="img/online_pay_icon.png"
                              alt="paypal"
                              className="img-responsive rounded shadow-lg"
                              style={{
                                height: "50px",
                                backgroundColor: "rgb(227 225 222)",
                                backgroundBlendMode: "multiply"
                              }}
                            />
                          </div>
                          <p className="mt-1 text-center">ONLINE BEZAHLEN</p>
                        </div>
                      </div>
                    )
                  }
                  {isPaymentCanceled && (
                      <div style={{ textAlign: "center", color: "red", marginTop: "2%", padding: "2%" }}>
                        <p style={{ fontSize: "1.5em", fontWeight: "bold" }}>Achtung!</p>
                        <p style={{ fontSize: "1.2em" }}>Mit deiner Bezahlung hat anscheinend etwas nicht funktioniert.</p>
                        <p style={{ fontSize: "1.2em" }}>Bitte wende dich an unsere Vapianisti.</p>
                      </div>
                  )}
                  <div className="ps-2">
                    {" "}
                    <b>*inklusive Service</b>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <ModalInterface
        display={showPay}
        handleClose={() => {
          dispatch(SetOrderPayment([]));
          setShowPay(false);
          setBillAmount(finalBill);
          setSelectedBill(false);
          setDivideBill(false);
        }}
        center={true}
        title={
          <>
            <div style={{ textAlign: "right" }}>
              <span
                role="button"
                className="fa-stack fa-lg fs-6 align-top mt-2"
                onClick={() => {
                  dispatch(SetOrderPayment([]));
                  setShowPay(false);
                  setBillAmount(finalBill);
                  setDivideBill(false);
                  setSelectedBill(false);
                }}>
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-close fa-stack-1x fa-inverse text-white"></i>
              </span>
              <br />
              <div style={{ textAlign: "center", marginTop: "-20px" }}>
                <h2
                  style={{
                    color: "#3CAF5A",
                    fontWeight: 500,
                    fontSize: "53px"
                  }}>
                  {" "}
                  GRAZIE MILLE!
                  {/* THANK YOU */}
                </h2>
              </div>
            </div>
          </>
        }
        closeEvent={() => {
          dispatch(SetOrderPayment([]));
          setShowPay(false);
          setBillAmount(finalBill);
          setSelectedBill(false);
          setDivideBill(false);
        }}
        className={"text-center"}
        bodyClass="text-center"
        content={
          <>
            <PaymentModal
              selectedBillAmount={selectedBill ? selectedBillAmount : 0}
              divisionAmount={
                divisionAmount > 0 && paymentType === "pay_divide" ? divisionAmount : 0
              }
              finalDivideCount={divideBill ? parseInt(finalDivideCount) : 0}
              fullAmount={checkPayment[0]?.full_amount}
              paidAmount={checkPayment[0]?.paid_amount}
              setDivisionAmount={setDivisionAmount}
              paymentType={divideBill ? "pay_divide" : selectedBill ? "pay_selected" : "pay_all"}
              payWaiterSelected={payWaiterSelected}
              salesEntries={selectedBill ? selectedItems : se}
              setShowFinal={setShowFinal}
              billAmount={billAmount}
              setfinalAmount={setFinalAmount}
              isSelectedBill={selectedBill}
              isDivide={divideBill}
              setShowPay={setShowPay}
              setShowDivide={setShowDivide}
              setShowPaypal={setShowPaypal}
              setShowCredit={setShowCredit}


              isPaySelected={selectedBill}
              selectedItems={selectedItems}



            />
          </>
        }
      />

      <ModalInterface
        display={showDivide}
        handleClose={() => {
          setShowDivide(false);
          setDivideBill(false);
          setDivisionCount(1);
        }}
        center={true}
        title={
          <>
            <div style={{ textAlign: "right" }}>
              <span
                role="button"
                className="fa-stack fa-lg fs-6 align-top mt-2"
                onClick={() => {
                  setShowDivide(false);
                  setDivideBill(false);
                  setDivisionCount(1);
                }}>
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-close fa-stack-1x fa-inverse text-white"></i>
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="./img/salad.png"
                style={{ width: "180px", height: "150px" }}
                alt="icon"></img>
            </div>
            <div
              className="text-center"
              style={{
                color: "#00a94d",
                fontSize: "53px",
                fontWeight: 500,
                marginTop: "-22px"
              }}>
              TEILEN
            </div>
          </>
        }
        closeEvent={() => {
          setShowDivide(false);
          setDivideBill(false);
          setDivisionCount(1);
        }}
        content={
          <>
            {" "}
            <DivideModal
              billAmount={billAmount}
              fullAmount={checkPayment[0]?.full_amount}
              paidAmount={checkPayment[0]?.paid_amount}
              divisionAmount={divisionAmount}
              setShowPay={setShowPay}
              bill={finalBill}
              setShowDivide={setShowDivide}
              setCount={setDivisionCount}
              updateBill={calculateBill}
              setFinalDivideCount={setFinalDivideCount}
            />
          </>
        }
      />

      <ModalInterface
        display={showFinal}
        center={true}
        handleClose={() => {
          setShowFinal(false);
        }}
        title={
          <>
            <div style={{ textAlign: "right" }}>
              <span
                className="fa-stack fa-lg fs-6 align-top mt-2"
                onClick={() => setShowFinal(false)}>
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-close fa-stack-1x fa-inverse text-white"></i>
              </span>
            </div>
          </>
        }
        closeEvent={() => setShowFinal(false)}
        bodyClass="text-center"
        content={
          <>
            {" "}
            <OrderPlacedModal
              bill={
                finalBill
                // .toFixed(2)
              }
              showFinal={showFinal}
              handleClose={handleClose}
              setShowFinal={setShowFinal}
              setShowPay={setShowPay}
              setmodalProceed={setmodalProceed}
              modalProceed={modalProceed}
            />
          </>
        }
      />
      <ModalInterface
        display={modalProceed}
        size="lg"
        center={true}
        style={{ position: "relative" }}
        handleClose={() => {
          // setShowCart(false);
          setShowFinal(false);
          setmodalProceed(false);
        }}
        title={<></>}
        bodyStyles={{
          maxHeight: "350px",
          marginTop: "-40px",
          overflow: "hidden !important"
        }}
        isCart={true}
        content={
          <>
            <div
              style={{ textAlign: "right", marginTop: "15px" }}
              onClick={() => {
                setmodalProceed(false);
                setShowFinal(false);
              }}>
              <span className="fa-stack fa-lg fs-6 align-top mt-2" style={{ zIndex: "999" }}>
                <i className="fa fa-circle fa-stack-2x text-danger"></i>
                <i className="fa fa-close fa-stack-1x fa-inverse text-white"></i>
              </span>
            </div>
            <div className="text-center">
              <div className="divideThank">
                <h1>DANKE</h1>
                <h2>DANKE wir haben deine Bestellung erhalten</h2>
              </div>
              <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                  <Slider {...settings}>
                    {images.map((url) => (
                      <i key={uuidv4()}>
                        <img src={`./img/goto/${url}.png`} alt="carousel" height={50} width={50} />
                      </i>
                    ))}
                  </Slider>
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }}>
                <Col md="6" lg="6" sm="6" xs="6" className="mt-4">
                  <Link to={"/guestbook"} style={{ textDecoration: "none" }}>
                    <Button
                      className="text-center text-white border border-info"
                      size="lg"
                      style={{
                        backgroundColor: "#17B5C2",
                        width: "150px",
                        boxShadow: "5px 5px 10px -5px #000",
                        fontWeight: " 500",
                        fontSize: "22px"
                      }}>
                      WALL OF FAME
                    </Button>
                  </Link>
                </Col>
                <Col md="6" lg="6" sm="6" xs="6" className="mt-4">
                  <Link to={"/home"} style={{ textDecoration: "none" }}>
                    <Button
                      className="text-center text-white border border-info"
                      size="lg"
                      style={{
                        backgroundColor: "#17B5C2",
                        width: "150px",
                        boxShadow: "5px 5px 10px -5px #000",
                        fontWeight: " 500",
                        fontSize: "22px"
                      }}>
                      HOME
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </>
        }
        className={"text-dark pt-2"}
      />

      <ModalInterface
        display={showPayPal}
        handleClose={() => {
          setShowPaypal(false);
        }}
        closeEvent={() => setShowPaypal(false)}
        bodyClass="paypalPay"
        content={
          <>
            {" "}
            <PaypalModal
              showPayPal={showPayPal}
              setShowPaypal={setShowPaypal}
              setShowFinal={setShowFinal}
              bodyClass="paypalPay"
              setmodalProceed={setmodalProceed}
              handleClose={() => {
                setShowPaypal(false);
              }}
              closeEvent={() => setShowPaypal(false)}
            />
          </>
        }
      />

      <ModalInterface
        display={showCredit}
        handleClose={() => {
          dispatch(SetOrderPayment([]));
          setShowCredit(false);
        }}
        closeEvent={() => {
          dispatch(SetOrderPayment([]));
          setShowCredit(false);
        }}
        bodyClass="text-center"
        content={
          <>
            {" "}
            <CreditModal
              showCredit={showCredit}
              setShowCredit={setShowCredit}
              finalAmount={finalAmount}
              setShowCreditCard={setShowCreditCard}
              setShowFinal={setShowFinal}
              setmodalProceed={setmodalProceed}
              bodyClass="text-center"
              handleClose={() => {
                setShowCredit(false);
              }}
              closeEvent={() => setShowCredit(false)}
            />
          </>
        }
      />

      <ModalInterface
        display={showCreditCard}
        handleClose={() => {
          setShowCreditCard(false);
        }}
        closeEvent={() => setShowCreditCard(false)}
        bodyClass="text-center"
        content={
          <>
            {" "}
            <CreditCardModal
              showCreditCard={showCreditCard}
              setShowCreditCard={setShowCreditCard}
              setShowFinal={setShowFinal}
              finalAmount={finalAmount}
              setmodalProceed={setmodalProceed}
              bodyClass="text-center"
              handleClose={() => {
                setShowCreditCard(false);
              }}
              closeEvent={() => setShowCredit(false)}
            />
          </>
        }
      />
      <OrderConformationModal
        setShowConfirmOrder={setShowConfirmOrder}
        showConfirmOrder={showConfirmOrder}
        // payOption={payOption}
        // setPayOption={setPayOption}
        // setShowPay={setShowPay}
        // updateBill={calculateBill}
        // setShowDivide={setShowDivide}
        // setDivideBill={setDivideBill}
        // setSelectedBill={setSelectedBill}
        triggerPayWaiter={handleWaiterPayment}
        setPaymentType={setPaymentType}
        paymentType={paymentType}
      />
    </>
  );
};

export default Bill;
